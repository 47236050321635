const experimentVariant = ({ store, $config }, inject) => {
  inject('experimentVariant', (flag) => {
    const experiment = store.getters.isExperimentEnabled(flag, $config.ENVIRONMENT);
    if (!experiment) {
      return undefined;
    }
    return store.getters.experimentVariant(flag);
  });
};

export default experimentVariant;
