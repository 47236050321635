/* eslint-disable no-shadow */
export const state = () => ({
  data: undefined,
});

export const mutations = {
  set(state, _data) {
    state.data = _data;
  },
};

export const actions = {

};
