/* eslint-disable no-shadow */
export const state = () => ({
  visibleExperiments: {},
  visitorId: null,
  variations: {},
  environmentId: null,
});

export const mutations = {
  setVariation(state, experimentVariant) {
    state.variations[experimentVariant.flag] = experimentVariant;
  },
  setEnvironmentId(state, environmentId) {
    state.environmentId = environmentId;
  },
  setVisitorId(state, visitorId) {
    state.visitorId = visitorId;
  },
  addExperiment(state, experiment) {
    if (state.visibleExperiments[experiment.name]) {
      return;
    }
    if (!Object.keys(this.state.flags.features).includes(experiment.name)) {
      return;
    }
    const availableExperiment = state.variations[experiment.name];
    if (!availableExperiment) {
      return;
    }
    this.$experimentExpose(
      state.visitorId,
      state.environmentId,
      availableExperiment.variationGroupId,
      availableExperiment.variationId,
      availableExperiment.campaignId,
      experiment.name,
    );
    state.visibleExperiments[experiment.name] = experiment.variant;
  },
};
