<template>
  <svg class="stroke-current" width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m1.5 1.5 5 5-5 5"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {

};
</script>

<style>

</style>
