export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const nginxDecodeUserId = (nginxUserIdCookie) => {
  const hex = window
    .atob(nginxUserIdCookie)
    .split('')
    .map((aChar) => `0${aChar.charCodeAt(0).toString(16)}`.slice(-2))
    .join('')
    .toUpperCase();
  let hexConstructed = '';
  for (let i = 0; i < hex.length; i += 8) {
    const dword = hex.substring(i, i + 8);
    const hexed = Number(`0x${dword.match(/../g)?.reverse().join('')}`)
      .toString(16)
      .padStart(8, '0')
      .toUpperCase();
    hexConstructed += hexed;
  }
  return hexConstructed;
};
