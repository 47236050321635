<template>
  <header class="bg-blue-600 relative leading-normal">
    <ReferralCashback />
    <div ref="highlightElement" class="bg-blue-600">
      <section
        class="md:container top-menu md:h-auto fixed md:static md:py-7 md:px-10 bg-blue-600 md:bg-transparent w-full md:flex flex-col md:flex-row md:items-center z-30"
        :class="{
          'top-menu--collapse': collapse,
          'top-menu--scrolled': scrollTop,
          'h-full': !collapse,
          flex: !collapse,
        }"
      >
        <div
          class="header-logo-container w-full md:py-0 md:w-auto flex place-content-between px-3 md:px-0 transition-all duration-200"
          :class="{
            'py-1': scrollTop && collapse,
            'py-4': !collapse || !scrollTop,
          }"
        >
          <div class="flex items-center">
            <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
            <a
              href="/"
              class="top-menu__logo-wrapper p-3 md:-ml-3 -ml-4px"
              @click="clear"
            >
              <Logo />
            </a>
            <PartnerLogo />
          </div>
          <div class="inline-flex items-center">
            <a href="/login/" @click="clear">
              <IconUser class="mr-3 md:hidden w-5 h-5" />
            </a>
            <ToggleMenu
              class="top-menu__toggle-menu md:hidden"
              :open="!collapse"
              @toggled="toggleMenu"
            />
          </div>
        </div>

        <nav
          class="text-xl md:text-base w-screen header-nav h-0 flex-1 flex flex-col md:static top-36 left-0 right-0 bg-white md:bg-transparent overscroll-contain"
          :class="{
            'header-nav--collapse': collapse,
            'header-nav--mega': megaMenuOpen,
          }"
        >
          <div
            ref="scroller"
            class="h-full md:overflow-y-visible w-full flex flex-col md:static md:flex-row md:items-center items-stretch overscroll-contain"
            :class="{
              'overflow-y-scroll': !isSearchActive && !languagesExpanded,
              relative: isSearchActive || languagesExpanded,
            }"
          >
            <ul
              class="header-nav__menu md:mx-auto flex md:flex-row flex-col min-h-max"
              :class="{
                'header-nav__menu--toggled': megaMenuOpen,
                'hidden md:flex': languagesExpanded,
              }"
            >
              <li
                class="header-nav__menu__item"
                :class="{ 'header-nav__menu__item--sub': megaMenuOpen }"
              >
                <div class="text-black md:text-white w-full md:w-auto">
                  <LazyHydrate when-idle>
                    <MegaMenu
                      :open="megaMenuOpen"
                      :expression="searchExpression"
                      @toggled="megaMenuToggled"
                    />
                  </LazyHydrate>
                </div>
              </li>
              <li v-if="zenDeskLangCode" class="header-nav__menu__item">
                <a
                  class="header-nav__menu__item__link"
                  :href="`https://parkos.zendesk.com/hc/${zenDeskLangCode}`"
                  rel="nofollow"
                  @click="clear"
                >
                  <div
                    class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"
                  >
                    {{ $i18n("general.customer-service") }}
                  </div>
                  <IconCaretRight
                    class="ml-auto text-black-alt-500 block md:hidden"
                  />
                </a>
              </li>
              <li v-if="aboutPageLink" class="header-nav__menu__item">
                <a
                  class="header-nav__menu__item__link"
                  :href="aboutPageLink"
                  @click="clear"
                >
                  <div
                    class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"
                  >
                    {{ aboutPageTitle }}
                  </div>

                  <IconCaretRight
                    class="ml-auto text-black-alt-500 block md:hidden"
                  />
                </a>
              </li>
            </ul>
            <ul
              class="header-nav__menu header-nav__menu--abs flex md:flex-row flex-col"
              :class="{
                'header-nav__menu--sub': languagesExpanded,
                'hidden md:flex': megaMenuOpen,
              }"
            >
              <li class="header-nav__menu__item header-nav__menu__item--login">
                <a
                  class="header-nav__menu__item__link"
                  href="/login/"
                  @click="clear"
                >
                  <div
                    class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"
                  >
                    {{ $i18n("templates.header-login") }}
                  </div>
                  <IconCaretRight
                    class="ml-auto text-black-alt-500 block md:hidden"
                  />
                </a>
              </li>
              <li
                v-if="faqMeta"
                class="header-nav__menu__item pr-0 header-nav__menu__item--meta"
              >
                <a
                  class="header-nav__menu__item__link"
                  :href="faqPageLink"
                  @click="clear"
                >
                  <div
                    class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"
                  >
                    {{ faqPageTitle }}
                  </div>
                  <IconCaretRight
                    class="ml-auto text-black-alt-500 block md:hidden"
                  />
                </a>
              </li>
              <li
                class="header-nav__menu__item pr-0"
                :class="{
                  'header-nav__menu__item--toggled': languagesExpanded,
                }"
              >
                <MobileLanguages
                  class="block md:hidden"
                  :expanded="languagesExpanded"
                  @toggled="languagesToggled"
                />
                <Languages
                  v-if="!$paDeviceDetector.isMobile()"
                  class="font-sans hidden md:flex items-center"
                />
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </div>
    <div
      :class="be1024homRemoveUsps ? 'pb-10 md:pb-20' : 'pb-18 md:pb-40'"
      class="xs:container pt-83px md:pt-12 px-5 md:px-10 md:py-20 relative"
    >
      <LazyHydrate when-idle>
        <MetaReviews />
      </LazyHydrate>
      <experiment-toggle name="hom-value-prop">
        <template #a>
          <div class="flex items-center">
            <h1
              class="text-[20px] xs:text-[24px] font-cocogoose md:text-5xl airport-title-main flex mt-8 mb-3 md:my-7 text-white relative w-7/10 sm:w-full z-1"
            >
              {{ $i18n("general.compare-parking") }}
            </h1>
          </div>
        </template>
        <template #b>
          <div class="flex items-center">
            <h1
              class="text-[20px] xs:text-[24px] font-cocogoose md:text-5xl airport-title-main flex mt-8 mb-3 md:my-7 text-white relative w-7/10 sm:w-full z-1"
            >
              {{ $i18n("general.value-proposition-title") }}
            </h1>
          </div>
        </template>
      </experiment-toggle>
      <experiment-toggle name="threeMinToBook">
        <template #d>
          <QuickBookBadgeV3 class="sm:hidden" />
        </template>
      </experiment-toggle>
      <div class="hidden md:block text-white text-base font-sans relative z-1">
        {{ $i18n("general.compare-parking-prices-verbose") }}
      </div>
      <div class="flex flex-col md:block">
        <div
          id="search-section"
          class="inline-flex flex-col relative w-full"
          :class="{
            'md:pr-16':
              !isUSDomain && !isUKDomain && !isEUDomain && !isAUDomain,
            'md:pr-2': isUSDomain || isUKDomain || isEUDomain || isAUDomain,
          }"
        >
          <experiment-toggle name="threeMinToBook">
            <template #a>
              <QuickBookBadge :show-airports-dropdown="showAirportsDropdown" />
            </template>
            <template #b>
              <QuickBookBadgeV4
                :show-airports-dropdown="showAirportsDropdown"
              />
            </template>
          </experiment-toggle>

          <LazyHydrate when-idle when-visible>
            <experiment-toggle
              name="async-datepicker-vs-custom-datepicker"
              class="relative z-1"
            >
              <template #a>
                <Search
                  ref="searchForm"
                  class="mt-7"
                  :class="{ 'mt-2 md:mt-7': be1024homRemoveUsps }"
                  :show-airports="showAirportsDropdown"
                />
              </template>
              <template #b>
                <custom-search
                  ref="searchForm"
                  class="mt-7"
                  :class="{ 'mt-2 md:mt-7': be1024homRemoveUsps }"
                  :show-airports="showAirportsDropdown"
                />
              </template>
            </experiment-toggle>
          </LazyHydrate>
        </div>
        <div
          v-if="!be1024homRemoveUsps"
          class="flex flex-col xs:flex-row place-content-between mt-7"
          :class="{
            'max-w-4xl': !showAirportsDropdown,
            'max-w-6xl': showAirportsDropdown,
          }"
        >
          <div
            class="order-2 xs:order-1 header-checkmarks flex flex-col md:flex-row mt-5 xs:mt-0"
          >
            <div
              class="my-2 md:my-0 font-sans md:mr-10 text-white flex items-center relative"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n("templates.header-usp-1") }}
              </div>
            </div>
            <div
              class="my-2 md:my-0 font-sans md:mr-10 text-white flex items-center z-1"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n("templates.header-usp-2") }}
              </div>
            </div>
            <div
              class="my-2 md:my-0 font-sans text-white flex items-center z-1"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n("templates.header-usp-3") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <be606Usps v-if="be606ukQuickWinsRunning" />
      <LazyHydrate class="hidden md:block" when-idle when-visible>
        <WorldMapParkos
          class="z-0 worldmap pointer-events-none max-w-3xl absolute -right-56 top-1/2 -mt-12"
        />
      </LazyHydrate>
    </div>
  </header>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import MegaMenu from "~/components/header/MegaMenu.vue";
import MetaReviews from "~/components/header/MetaReviews/MetaReviews.vue";
import IconAirplane from "~/components/icons/IconAirplane.vue";
import IconCheckmark from "~/components/icons/IconCheckmark.vue";
import Languages from "~/components/header/Languages.vue";
import ToggleMenu from "~/components/header/ToggleMenu.vue";
import IconCaretRight from "~/components/icons/IconCaretRight.vue";
import IconUser from "~/components/icons/IconUser.vue";
import MobileLanguages from "~/components/header/MobileLanguages.vue";
import ReferralCashback from "~/components/ReferralCashback.vue";
import CustomSearch from "~/components/custom-search/index.vue";

import Search from "~/components/experiments/async-datepicker/index.vue";

import ExperimentToggle from "~/components/experiments/ExperimentToggle.vue";
import WorldMapParkos from "~/components/header/WorldMapParkos.vue";
import QuickBookBadge from "~/components/QuickBookBadge.vue";
import QuickBookBadgeV3 from "~/components/experiments/3min-to-book-badge/QuickBookBadgeV3.vue";
import QuickBookBadgeV4 from "~/components/experiments/3min-to-book-badge/QuickBookBadgeV4.vue";
import be606Usps from "@/components/experiments/be-606-uk-quick-wins/be-606-usps.vue";
import Logo from "../Logo.vue";
import PartnerLogo from "./PartnerLogo.vue";

const {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} = require("body-scroll-lock");

export default {
  components: {
    be606Usps,
    LazyHydrate,
    PartnerLogo,
    Logo,
    MegaMenu,
    MetaReviews,
    WorldMapParkos,
    Search,
    IconAirplane,
    IconCheckmark,
    Languages,
    ToggleMenu,
    IconCaretRight,
    IconUser,
    MobileLanguages,
    ReferralCashback,
    QuickBookBadge,
    ExperimentToggle,
    QuickBookBadgeV3,
    QuickBookBadgeV4,
    CustomSearch,
  },
  data() {
    return {
      isMapReady: false,
      collapse: true,
      scrollTop: 0,
      megaMenuOpen: false,
      searchExpression: "",
      languagesExpanded: false,
      showAirportsDropdown: true,
      be606ukQuickWinsRunning: false,
      be1024homRemoveUsps: false,
    };
  },
  computed: {
    experimentVariant() {
      return this.$store?.getters?.experimentVariant(this.name);
    },
    isUSDomain() {
      return this.$store.state.language.lang === "en-us";
    },
    isUKDomain() {
      return this.$store.state.language.lang === "en-gb";
    },
    isEUDomain() {
      return this.$store.state.language.lang === "en-eu";
    },
    isAUDomain() {
      return this.$store.state.language.lang === "en-au";
    },
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    pricePerDay() {
      if (this.airport.from_price) {
        return new Intl.NumberFormat(this.language.lang, {
          style: "currency",
          currency: this.language.currency.iso_code ?? "EUR",
        }).format(this.airport.from_price / 8);
      }
      return null;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        const langCode = this.language.lang;
        if (langCode === "en-eu") {
          return "en-150";
        }
        if (langCode === "da-dk") {
          return "da";
        }
        if (langCode === "sv-se") {
          return "sv";
        }
        return langCode;
      }
      return null;
    },
    aboutPageLink() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return `/${currentContent.slug}.html`;
      }
      return null;
    },
    aboutPageTitle() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return currentContent.title;
      }
      return null;
    },
    aboutPageContent() {
      return this.$store.state.pageContent.aboutPageContent;
    },
    metaPages() {
      return this.$store.state.metaPages;
    },
    faqMeta() {
      return this.metaPages.faqMeta;
    },
    faqPageLink() {
      return `//${this.$paths.host}/${this.faqMeta.slug}/`;
    },
    faqPageTitle() {
      return this.faqMeta.title;
    },
    isSearchActive() {
      return !!(this.searchExpression && this.searchExpression !== "");
    },
  },
  created() {
    this.be606ukQuickWinsRunning =
      this.$store?.getters?.experimentVariant(this.name) === "b";

    this.be1024homRemoveUsps =
      this.$store?.getters?.experimentVariant("homRemoveUsps") === "b";
  },
  mounted() {
    document.addEventListener("scroll", this.scrollCheck);
  },
  methods: {
    scrollCheck() {
      this.scrollTop = window.scrollY;
    },
    toggleMenu(open) {
      this.collapse = !open;
      const { scroller } = this.$refs;
      if (this.collapse) {
        clearAllBodyScrollLocks();
        this.resetData();
      } else {
        disableBodyScroll(scroller);
      }
    },
    megaMenuToggled(show) {
      this.megaMenuOpen = show;
      if (show) {
        this.$refs.highlightElement.skipScrollLock =
          !this.$paDeviceDetector.isMobile();
        this.$store.commit("highlight/show", this.$refs.highlightElement);
      } else {
        this.$store.dispatch("highlight/hide");
      }
    },
    clear() {
      this.toggleMenu();
    },
    languagesToggled(expanded) {
      this.languagesExpanded = expanded;
      disableBodyScroll(this.$refs.scroller);
    },
    resetData() {
      this.searchExpression = "";
      this.megaMenuOpen = false;
      this.languagesExpanded = false;
      this.$store.dispatch("highlight/hide");
    },
    changeAirport() {
      this.showAirportsDropdown = true;
      this.$nextTick(() => {
        this.$refs.searchForm.handleAirportToggle();
      });
    },
  },
};
</script>

<style lang="scss">
.worldmap {
  transform: translate3d(0, -50%, 0);
}

.top-menu {
  $self: &;

  &--collapse {
    @apply place-content-between h-auto;
  }

  &__search {
    @apply block md:hidden;
  }
}

.header-nav {
  $self: &;

  a {
    &:hover {
      @media (min-width: 1080px) {
        @apply underline;
      }
    }
  }

  &--collapse {
    @apply hidden md:flex h-auto;
  }

  &--mega {
    @apply top-18;
  }

  @apply md:h-auto;

  &__menu {
    $self: &;

    &--toggled {
      #{$self}__item {
        @apply hidden md:flex;

        &--sub {
          @apply flex;
        }
      }
    }

    &__item {
      @apply flex flex-1 border-b border-athens md:border-none select-none w-full xs:w-auto;
      min-height: max-content;

      &--login {
        @apply hidden md:flex;
      }

      &--meta {
        @apply md:hidden;
      }

      &__link {
        @apply py-6 place-content-between items-center w-full px-6 md:w-auto md:px-3 md:py-2 text-black md:text-white font-sans flex justify-between cursor-pointer;

        &:hover {
          @apply no-underline;
        }
      }
    }
  }
}

.icon-checkmark {
  @apply text-blue-alt mr-2 md:mr-3 md:scale-150 lg:scale-100 transform;
}
</style>
