import * as DOMPurify from 'dompurify';

// eslint-disable-next-line no-empty-pattern
export default ({ }, inject) => {
  const sanitize = (html, stripAll = false, allowTags = []) => {
    if (!html || html === '') {
      return '';
    }

    return DOMPurify.sanitize(html, { ALLOWED_TAGS: allowTags });
  };

  inject('sanitize', sanitize);
};
